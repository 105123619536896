/**
 * @typedef {Object} TimeUnitFilterValue
 * @property {string} text
 * @property {string} value
 */

/** @type {TimeUnitFilterValue[]} */
export const timeUnitFilterValues = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'Billable',
    value: 'billable',
  },
  {
    text: 'Approved',
    value: 'approved',
  },
];
