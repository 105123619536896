<template>
  <div>
    <v-row class="actions-bar pa-4 bar-shadow primary-background">
      <v-col>
        <ReportPageFilters
          @getFilteredReportsList="getFilteredReportsList"
        />
      </v-col>
      <v-col class="actions">
        <v-tooltip bottom>
          <template
            #activator="{ on }"
          >
            <Button
              v-show="isAddTimeReportButtonShown"
              id="sync-all-button"
              color="primary"
              :on="!largeScreenHelper ? on : null"
              @clickAction="isDialogOpened = true"
            >
              <v-icon v-if="!largeScreenHelper">add</v-icon>
              <span v-else>{{ addTimeReportText }}</span>
            </Button>
          </template>
          <span>{{ addTimeReportText }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row class="pa-4">
      <v-card>
        <ReportTable
          :date="dateRangeFilter"
          :items="reportsFiltered"
          @refreshData="getFilteredReportsList"
        />
      </v-card>
    </v-row>

    <AddReportDialog
      :is-opened="isDialogOpened"
      @close="isDialogOpened = false"
      @save="getFilteredReportsList"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import { Button } from '@/components/shared';
import { isLargeScreen } from '@/helpers/screen.helper';

import AddReportDialog from './AddReportDialog.vue';
import ReportPageFilters from './ReportPageFilters.vue';
import ReportTable from './ReportTable.vue';

export default {
  components: {
    Button,
    AddReportDialog,
    ReportPageFilters,
    ReportTable,
  },
  data() {
    return {
      addTimeReportText: 'Add Time Report',
      isFiltersOpened: true,
      isDialogOpened: false,
    };
  },
  computed: {
    ...mapGetters('reports/filters', [
      'reportsFiltered',
      'dateRangeFilter',
      'statusFilter',
    ]),
    ...mapGetters('reports/main', ['formProjectsList']),

    largeScreenHelper() {
      return isLargeScreen(this.$vuetify.breakpoint, false);
    },
    isAddTimeReportButtonShown() {
      return !!this.formProjectsList.length;
    },
  },
  methods: {
    ...mapActions('reports/filters', ['getFilteredReports']),

    getFilteredReportsDebounced: debounce(function (filter) {
      this.getFilteredReports(filter);
    }, 500),

    getFilteredReportsList(filter) {
      this.getFilteredReportsDebounced(filter);
    },
  },
};
</script>

<style lang="less">
  @import "~@/styles/actions-bar";
</style>
