<template>
  <div>
    <v-data-table
      id="report-table"
      :headers="headers"
      :items="reportsFiltered"
      item-key="id"
      fixed-header
      disable-pagination
      hide-default-footer
      :height="defaultTableHeight"
      :dense="denseTables"
      :value="selectedReports"
      :custom-sort="customSort"
      :sort-by="['project_title']"
      @input="setSelectedReports"
    >
      <template #item="{ isSelected, select, item }">
        <tr
          :active="isSelected"
          active-class="highlighted"
          @click="navigateToDetails(item)"
        >
          <td
            v-for="(header, index) in headers.filter(h => !h.hidden)"
            :key="index"
          >
            <template v-if="header.value === 'time_period'">
              {{ timePeriod(item['start_date'], item['end_date']) }}
            </template>
            <template v-if="header.value === 'creation_date'">
              {{ dateTimeFormat(item['creation_date']) }}
            </template>
            <template v-else-if="header.value === 'project_title'">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span
                    class="cell-content-with-ellipsis"
                    v-on="on"
                  >
                    <span>
                      {{ item[header.value] }}
                    </span>
                  </span>
                </template>
                <span>
                  {{ item[header.value] }}
                </span>
              </v-tooltip>
            </template>
            <template v-else>
              {{ item[header.value] }}
            </template>
          </td>
          <td class="action-column">
            <v-row justify="end">
              <v-btn
                text
                icon
                small
                :disabled="!canDelete(item) || removable"
                @click.stop="askReportDeleteConfirmation(item)"
              >
                <v-icon>delete_outline</v-icon>
              </v-btn>
            </v-row>
          </td>
        </tr>
      </template>

      <template #footer>
        <v-divider />
        <v-row class="table-footer">
          <div class="footer-text">Total records: {{ reportsFiltered.length }}</div>
        </v-row>
      </template>
    </v-data-table>

    <DeletionConfirmationDialog
      :dialog="confirmationDialog"
      title="Confirmation"
      text="Are you sure you want to delete this report?"
      confirmation-button-text="Delete"
      @confirmationAction="reportDeleteConfirmation"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { Dialog as DeletionConfirmationDialog } from '@/components/shared';
import { defaultTableHeight } from '@/constants/tableHeights';
import { DateHelper, DateFormat } from '@/helpers/date.helper';

export default {
  components: {
    DeletionConfirmationDialog,
  },
  props: {
    date: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultTableHeight,
      headers: [
        {
          text: 'Project',
          value: 'project_title',
        },
        {
          text: 'Time Period',
          value: 'time_period',
          width: '220px',
        },
        {
          text: 'Author',
          value: 'creator_employee_name',
          width: '220px',
        },
        {
          text: 'Office',
          value: 'office_title',
          width: '100px',
        },
        {
          text: 'Type',
          value: 'report_type',
          width: '100px',
        },
        {
          text: 'Creation Date',
          value: 'creation_date',
          width: '200px',
        },
        {
          text: 'Status',
          value: 'status',
          width: '150px',
        },
        {
          hidden: true,
          width: '50px',
        },
      ],
      confirmationDialog: false,
      itemToDelete: '',
      removable: false,
      selectedReports: [],
    };
  },
  computed: {
    ...mapGetters('user/settings', [
      'denseTables',
      'dateFormatSettings',
    ]),
    ...mapState('employees/single', ['employee']),
    ...mapGetters('reports/filters', ['reportsFiltered']),
  },
  watch: {
    reportsFiltered() {
      const reportIds = this.reportsFiltered.map((item) => item.id);

      this.selectedReports = this.selectedReports.filter((report) => reportIds.includes(report.id));
    },
  },
  methods: {
    ...mapActions('reports/main', ['deleteReport']),

    refreshData() {
      this.$emit('refreshData');
    },
    askReportDeleteConfirmation(item) {
      this.itemToDelete = item;
      this.confirmationDialog = true;
    },
    async reportDeleteConfirmation(confirmed) {
      if (confirmed) {
        this.removable = true;

        try {
          await this.deleteReport(this.itemToDelete.id);
          this.deleteReportFromStore();
        } finally {
          this.removable = false;
        }
      }

      this.confirmationDialog = false;
    },
    deleteReportFromStore() {
      const index = this.reportsFiltered.findIndex((item) => item.id === this.itemToDelete.id);

      if (index >= 0) {
        this.reportsFiltered.splice(index, 1);
      }
    },
    canDelete: (report) => !report.is_report_locked,
    timePeriod(startDate, endDate) {
      const start = DateHelper.format(startDate, DateFormat.DATE_WITH_SHORT_MONTH);
      const end = DateHelper.format(endDate, DateFormat.DATE_WITH_SHORT_MONTH);

      return start === end ? `${start}` : `${start} - ${end}`;
    },
    dateTimeFormat(dateTime) {
      const localTime = DateHelper.utcToLocal(dateTime);

      return DateHelper.getDateTimeAccordingSettings(localTime, this.dateFormatSettings);
    },
    navigateToDetails(item) {
      this.$router.push({ name: 'Report Details', params: { id: item.id } });
    },
    setSelectedReports(selectedReports) {
      this.selectedReports = selectedReports;
    },
    customSort(items, headers, sortDesc) {
      const sortOrder = sortDesc[0] ? -1 : 1;
      const propertyName = headers[0] === 'time_period' ? 'start_date' : headers;

      items.sort((firstItem, secondItem) => {
        const comparationResult = firstItem[propertyName] < secondItem[propertyName] ? -1 : 1;

        return sortOrder * comparationResult;
      });

      return items;
    },
  },
};
</script>

<style lang="less">
  #report-table {
    overflow-x: hidden;
    overflow-y: auto;

    tr {
      cursor: pointer;
    }

    .action-column {
      .row {
        margin: 0;

        .v-btn {
          color: #555;
          margin: 1px;
          height: inherit;
          width: inherit;
          &:hover {
            background-color: rgba(0,0,0,.1);
            transition: all 0.5s ease;
          }
        }
      }
    }

    .footer {
      height: 50px;
    }

    .cell-content-with-ellipsis {
      display: inline-block;
      width: 100%;
      min-width: 100px;
      position: relative;

      &:before {
        content: '&nbsp;';
        visibility: hidden;
      }

      span {
        position: absolute;
        left: 0;
        right: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
