<template>
  <v-row class="filters-row-wrapper">
    <v-col>
      <v-text-field
        id="project-name-filter"
        label="Project"
        class="secondary-background"
        prepend-inner-icon="search"
        single-line
        outlined
        dense
        hide-details
        :value="projectNameFilter"
        @input="projectNameFilterChanged"
      />
    </v-col>
    <v-col>
      <MultiChoiceFilter
        label="Office"
        class="secondary-background"
        item-text="name"
        outlined
        dense
        hide-details
        :items="userOffices"
        :value="userOffices.length === 1 ? [userOffices[0].id] : officeFilter"
        :disabled="isDisabledOfficeInput"
        @update="officesFilterChanged"
      />
    </v-col>
    <v-col>
      <MultiChoiceFilter
        label="Status"
        item-text="title"
        class="secondary-background"
        outlined
        dense
        hide-details
        :items="reportStatuses"
        :value="statusFilter"
        @update="statusFilterChanged"
      />
    </v-col>
    <v-col>
      <MultiChoiceFilter
        label="Type"
        item-text="text"
        class="secondary-background"
        outlined
        dense
        hide-details
        :items="reportTypes"
        :value="typeFilter"
        @update="typeFilterChanged"
      />
    </v-col>
    <v-col class="date-picker">
      <DateRange
        :value="dateRangeFilter"
        :display-format="dateRange"
        @input="customTimeRangeChange"
        @timeRangeMove="timeRangeMove"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

import { DateRange, MultiChoiceFilter } from '@/components/shared';
import { reportTypes } from '@/constants/report-types';
import reportStatuses from '@/constants/reportStatuses';
import { systemRolesNames } from '@/constants/roles';
import { DateHelper, DateFormat } from '@/helpers/date.helper';

export default {
  components: {
    DateRange,
    MultiChoiceFilter,
  },
  data: () => ({
    reportStatuses,
    reportTypes,
    search: '',
    systemRolesNames,
  }),
  computed: {
    ...mapGetters('shared/offices', ['userOffices']),
    ...mapGetters('auth/account', ['user', 'isUserDM']),
    ...mapGetters('reports/filters', [
      'dateRangeFilter',
      'projectNameFilter',
      'statusFilter',
      'typeFilter',
      'officeFilter',
    ]),

    dateRange() {
      const start = DateHelper.format(
        this.dateRangeFilter.startDate,
        DateFormat.DATE_WITH_SHORT_MONTH,
      );
      const end = DateHelper.format(
        this.dateRangeFilter.endDate,
        DateFormat.DATE_WITH_SHORT_MONTH,
      );

      return start === end ? `${start}` : `${start} - ${end}`;
    },

    isDisabledOfficeInput() {
      const isAdmin = this.user.role === systemRolesNames.admin;

      return !(isAdmin || this.isUserDM);
    },
  },
  created() {
    this.getOffices();
    this.$emit('getFilteredReportsList');
    this.getSystemRoles();
  },
  methods: {
    ...mapActions('reports/filters', [
      'changeCustomDateRange',
      'moveDateRange',
      'setProjectNameFilter',
      'setStatusFilter',
      'setTypeFilter',
      'setOfficesFilter',
    ]),
    ...mapActions('shared/roles', ['getSystemRoles']),
    ...mapCacheActions('shared/offices', ['getOffices']),

    projectNameFilterChanged(value) {
      this.setProjectNameFilter(value);
      this.$emit('getFilteredReportsList', 'project-name-filter');
    },
    statusFilterChanged(value) {
      this.setStatusFilter(value);
      this.$emit('getFilteredReportsList');
    },
    typeFilterChanged(value) {
      this.setTypeFilter(value);
      this.$emit('getFilteredReportsList');
    },
    officesFilterChanged(value) {
      this.setOfficesFilter(value);
      this.$emit('getFilteredReportsList');
    },
    async timeRangeMove(isForward) {
      await this.moveDateRange({
        isForward,
        dateRange: this.dateRangeFilter,
      });
      this.$emit('getFilteredReportsList');
    },
    async customTimeRangeChange(newDate) {
      await this.changeCustomDateRange(newDate);
      this.$emit('getFilteredReportsList');
    },
    handleSearchInput(value) {
      this.search = value;
    },
  },
};
</script>

<style lang="less">
  @import "~@/styles/actions-bar";
</style>
