<template>
  <Dialog
    is-persistent
    :title="title"
    :dialog="isOpened"
    :confirmation-button-text="confirmationButtonText"
    @confirmationAction="saveReport"
  >
    <template #content>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row class="pb-4">
          <v-col>
            <MultiChoiceFilter
              v-model="reportItem.projectIds"
              class="secondary-background"
              label="Project"
              item-text="title"
              item-value="id"
              hide-details
              outlined
              dense
              required
              with-select-all
              :with-select-all-handler="getActualProjects"
              :items="projects"
              :rules="projectRules"
              @update="projectSelected"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pr-2">
            <DatePicker
              ref="startDate"
              v-model="startDate"
              label="Start date"
            />
          </v-col>
          <v-col>
            <DatePicker
              ref="endDate"
              v-model="endDate"
              label="End date"
              :min-date="startDate"
            />
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col>
            <v-select
              v-model="type"
              :menu-props="{ offsetY: true }"
              :items="reportTypes"
              class="secondary-background"
              label="Type"
              hide-details
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col>
            <v-select
              v-model="timeUnitFilterValue"
              :menu-props="{ offsetY: true }"
              :items="timeUnitFilterValues"
              class="secondary-background"
              label="Time units"
              hide-details
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="isIncludeInvested"
              color="primary"
              label="Include invested employees"
              hide-details
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </Dialog>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import { Dialog, DatePicker, MultiChoiceFilter } from '@/components/shared';
import { reportTypes } from '@/constants/report-types';
import { timeUnitFilterValues } from '@/constants/time-units-filter-values';
import { DateHelper } from '@/helpers/date.helper';

/**
 * @typedef {import('@/constants/report-types').ReportType} ReportType
 * @typedef {import('@/constants/time-units-filter-values').TimeUnitFilterValue} TimeUnitFilterValue
 */

/**
 * @typedef {Object} ReportItem
 * @property {{ startDate: string; endDate: string }} date
 * @property {string} officeId
 * @property {string} projectIds
 * @property {string} title
 * @property {ReportType} type
 * @property {TimeUnitFilterValue} timeUnitFilterValue
 */

const getInitialStartDate = () => DateHelper.toIso(moment().subtract(1, 'months').startOf('month'));

const getInitialEndDate = () => DateHelper.toIso(moment().subtract(1, 'months').endOf('month'));

const defautType = reportTypes.find((type) => type.isDefault) || reportTypes[0];

/** @returns {ReportItem} */
const getInitialState = () => ({
  date: {
    startDate: getInitialStartDate(),
    endDate: getInitialEndDate(),
  },
  officeId: '',
  projectIds: [],
  title: '',
  type: defautType,
  timeUnitFilterValue: timeUnitFilterValues[0],
});

export default {
  components: {
    Dialog,
    DatePicker,
    MultiChoiceFilter,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: 'Add Report',
      confirmationButtonText: 'Add',
      projects: [],
      valid: true,
      reportItem: getInitialState(),
      search: '',
      projectRules: [(v) => !!v || 'Project is required'],
      startDate: getInitialStartDate(),
      endDate: getInitialEndDate(),
      reportTypes,
      timeUnitFilterValues,
      type: defautType.value,
      timeUnitFilterValue: timeUnitFilterValues[0].value,
      isIncludeInvested: false,
    };
  },
  computed: {
    ...mapGetters('auth/account', ['user', 'userProjects']),
    ...mapGetters('reports/main', ['reportIds', 'formProjectsList']),
    ...mapGetters('shared/roles', ['projectRolesMap']),

    /** @returns {ReportItem} */
    form() {
      return { ...this.reportItem };
    },
  },
  watch: {
    startDate(value) {
      if (!DateHelper.isDayBefore(value, this.endDate)) {
        const newEndDate = DateHelper.toIso(moment(value).endOf('month'));

        this.endDateChanged(newEndDate);
        this.endDate = newEndDate;
      }

      this.startDateChanged(value);
      this.startDate = value;
    },
    endDate(value) {
      this.endDateChanged(value);
    },
  },
  async created() {
    document.addEventListener('keydown', this.handleKeypress);
    document.addEventListener('keyup', this.handleKeyup);

    await Promise.all([this.getUserProjects(), this.getProjectRoles()]);

    this.projects = this.formProjectsList;
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeypress);
    document.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    ...mapActions('auth/account', ['getUserProjects']),
    ...mapActions('shared/roles', ['getProjectRoles']),
    ...mapActions('reports/main', ['createReport']),

    // TODO: rework datepicker to use `value` and `input` instead of v-model to get rid of crutches below
    startDateChanged(value) {
      this.reportItem.date.startDate = value;
    },
    endDateChanged(value) {
      this.reportItem.date.endDate = value;
    },
    async saveReport(confirmed) {
      if (!confirmed) {
        this.closeDialog();

        return;
      }

      if (!this.validate()) return;

      const reports = {
        project_ids: this.reportItem.projectIds,
        office_id: this.user.office,
        start_date: this.reportItem.date.startDate,
        end_date: this.reportItem.date.endDate,
        report_type: this.type,
        time_units_filter: this.timeUnitFilterValue,
        is_include_invested: this.isIncludeInvested,
      };

      try {
        await this.createReport(reports);
        this.$emit('save');
      } finally {
        switch (this.reportIds.length) {
          case 0:
            this.flashError('Please select projects to generate');
            break;
          case 1:
            this.closeDialog();
            this.navigateToDetails(this.reportIds[0]);
            break;
          default:
            this.closeDialog();
            this.flashSuccess(`${this.reportIds.length} reports have been generated successfully`);
        }
      }
    },
    closeDialog() {
      this.$emit('close');
      this.reset();
    },
    reset() {
      Object.assign(this.reportItem, getInitialState());
      this.$refs.startDate.date = getInitialStartDate();
      this.$refs.endDate.date = getInitialEndDate();
      this.resetValidation();
    },
    validate() {
      return this.$refs.form.validate();
    },
    handleSearchInput(value) {
      this.search = value;
    },
    handleKeypress(event) {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.closeDialog();
      }
    },
    handleKeyup(event) {
      if (event.key === 'Enter' && event.ctrlKey) {
        event.preventDefault();
        this.saveReport();
      }
    },
    projectSelected(value) {
      this.reportItem.projectIds = value;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    navigateToDetails(reportId) {
      this.$router.push({ name: 'Report Details', params: { id: reportId } });
    },
    getActualProjects(projects) {
      const dividerIndex = projects.findIndex((item) => item.divider);

      return dividerIndex === -1 ? projects : projects.slice(0, dividerIndex);
    },
  },
};
</script>
